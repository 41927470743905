define("discourse/plugins/discourse-mycad-required/initializers/discourse-mycad-required", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'discourse-mycad-required',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.12.1', api => {
        const user = api.getCurrentUser();
        let siteSettings = container.lookup('site-settings:main');

        // (post migration) used to load notification's translation from mycad
        $(document).ready(function () {
          let tag = document.createElement('script');
          tag.src = siteSettings.mycad_host + '/discourse/webhook/js/translates';
          document.getElementsByTagName('head')[0].appendChild(tag);
        });
        $(document).ajaxSend(function (event, jqxhr, settings) {
          // (post migration) used for gamification to count watching and tracking a post as following action
          // and notify mycad on each post follow of these types action
          if (user !== null && settings.url.match('/t/([0-9]+)/notifications') && $.inArray(settings.data, ['notification_level=2', 'notification_level=3']) !== -1) {
            let result = settings.url.match('/t/([0-9]+)/notifications');
            let topic_id = result[1];
            let title = document.title.split(' - ');
            $.ajax({
              type: 'POST',
              url: siteSettings.mycad_host + '/discourse/webhook/log_follow_activity',
              data: {
                topic_id: topic_id,
                title: title[0],
                user_id: user.external_id
              }
            });
          }

          // (post migration) used to track some pages and notify Timelab about it
          if (user !== null && (settings.url.match('/drafts/new_topic.json') || settings.url.match('/drafts/topic_([0-9]+).json') || settings.url.match('/t/([0-9]+)/([0-9]+).json') || settings.url.match('latest.json'))) {
            let timelab_selector = settings.url;
            if (settings.url.match('/drafts/new_topic.json')) {
              timelab_selector = '/questions/creation';
            } else if (settings.url.match('/drafts/topic_([0-9]+).json')) {
              timelab_selector = 'a[href=#answer-form]';
            } else if (settings.url.match('/t/([0-9]+)/([0-9]+).json')) {
              timelab_selector = '#questions_page .question_article h2 a';
            } else if (settings.url.match('latest.json')) {
              timelab_selector = '/questions';
            }
            $.ajax({
              type: 'POST',
              url: siteSettings.mycad_host + '/discourse/webhook/timelab/event/save',
              data: {
                timelab_selector: timelab_selector,
                user_id: user.external_id
              }
            });
          }
        });

        // (post migration) used to redirect old links
        api.decorateCookedElement(elem => {
          $(elem).find("a[href^='/user/']").attr('href', function () {
            return siteSettings.mycad_host + $(this).attr('href');
          });
          $(elem).find("a[href^='/tutos/']").attr('href', function () {
            return siteSettings.mycad_host + $(this).attr('href');
          });
          $(elem).find("img[src^='/sites/']").attr('src', function () {
            return siteSettings.mycad_host + $(this).attr('src');
          });
        }, {
          id: 'old-links-decorator'
        });

        // used to add a class to <html> in order recognize some specific pages
        api.onPageChange((url, title) => {
          if (url === '/' || url === '/latest' || url === '/unread' || url === '/top') {
            document.querySelector('html').classList.remove('forum-page');
            document.querySelector('html').classList.add('forum-homepage');
          } else {
            document.querySelector('html').classList.remove('forum-homepage');
            document.querySelector('html').classList.add('forum-page');
          }
        });
      });
    }
  };
});